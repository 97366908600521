import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Loadable from "@loadable/component";
import BlockContent from "@sanity/block-content-to-react";
import dateFormat from "dateformat";
import "../styles/events.scss";

const EventsPage = ({ data }) => {
  const BookingButton = Loadable(() =>
    import("../components/eventbriteButton")
  );

  const pageData = data.sanityUpcomingSales;
  const events = pageData.sales;
  const Event = ({ eventId, location, date, name }) => {
    const dateObj = new Date(date);
    const dateString = dateFormat(dateObj, "dddd, mmmm d, yyyy");
    return (
      <div className="card event" key={eventId}>
        <header className="card-header">
          <p className="card-header-title">{name}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="date">{dateString}</div>
            <div className="location">{location}</div>
            <BookingButton eventId={eventId} className="button is-primary">
              Reserve your spot
            </BookingButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <h3 className="title is-3">Upcoming events</h3>
      {pageData._rawText !== null && (
        <div className="text">
          <BlockContent blocks={pageData._rawText} />
        </div>
      )}
      <div className="events">
        {events.map(e => (
          <Event
            key={e.eventId}
            eventId={e.eventId}
            location={e.location}
            date={e.date}
            name={e.name}
          />
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanityUpcomingSales(_id: { eq: "single-upcomingSales" }) {
      _rawText
      sales {
        eventId
        location
        name
        date
      }
    }
  }
`;

export default EventsPage;
